<template>
    <div class="coreMetadata">
        <div class="handle-btn">
            <el-button type="primary" size="small" @click="handleSave">
                保存
            </el-button>
        </div>
        <el-form ref="form" :model="form" label-width="150px">
            <el-form-item label="信息类编号">
                <el-input v-model="form.xxlbh"></el-input>
            </el-form-item>
            <el-form-item label="信息类名称">
                <el-input v-model="form.xxlmc"></el-input>
            </el-form-item>
            <el-form-item label="数据来源">
                <el-input v-model="form.sjly"></el-input>
            </el-form-item>
            <el-form-item label="业务部门">
                <el-input v-model="form.ywbm"></el-input>
            </el-form-item>
            <el-form-item label="业务来源">
                <el-input v-model="form.ywly"></el-input>
            </el-form-item>
            <el-form-item label="入库条件">
                <el-input v-model="form.rktj"></el-input>
            </el-form-item>
            <el-form-item label="入库方式">
                <el-input v-model="form.rkfs"></el-input>
            </el-form-item>
            <el-form-item label="备注">
                <el-input v-model="form.sm"></el-input>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        entityId: {
            type: String,
        },
    },
    data() {
        return {
            form: {
                id: '',
                entity_id: '',
                xxlbh: '',
                xxlmc: '',
                sjly: '',
                ywbm: '',
                ywly: '',
                rktj: '',
                rkfs: '',
                sm: '',
            },
        };
    },
    watch: {
        entityId(val) {
            if (val) {
                this.entityId = val;
                this.getMetadataElement();
            }
        },
    },
    computed: {},
    methods: {
        // 获取目录对应的核心元数据信息
        getMetadataElement() {
            this.$axios
                .get('/interfaceApi/datacenter/metadata/element/get/' + this.entityId)
                .then(res => {
                    if (res) {
                        this.form = res;
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 保存目录对应的核心元数据信息
        handleSave() {
            if (this.entityId) {
                this.$axios
                    .post('/interfaceApi/datacenter/metadata/element/save', this.form)
                    .then(res => {
                        if (res) {
                            this.$message.success('保存成功！');
                        }
                    }).catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            } else {
                this.$message.warning('请先选中对应树节点添加！');
            }

        },
    },
    created() {
        if (this.entityId) {
            this.getMetadataElement();
        }
    },
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.coreMetadata
    width 100%
    padding .1rem 15% 0
    .handle-btn
        text-align right
        margin-bottom .1rem
</style>